import TeamService from "@/services/teamService.js";
import {
  checkHasSubscriptionAndSetCustomerId,
  recordEndUserLogin,
  recordTeamMemberLogin,
  getActiveTeamForEndUserEmail
} from "@/customApi";
import { getAssessmentResult } from "@/services/modules/api-queries";
import { handleUrlRedirect } from "@/services/authorization/url-redirect/url-redirect-service.js";
import { routes, assessmentRoutes } from "@/router/route-constants.js";

import { getAssessmentDetails } from "@/customApi";
import store from "@/store";
import router from "@/router";
import sentry from "@/sentry";

async function getRedirectDestination(teamMemberInfo, store) {
  let assessmentRedirect = store.getters.redirectReportAssessment;
  if (assessmentRedirect) {
    return await GetAssessmentRedirect(assessmentRedirect, store);
  }
  if (store.getters.isSimpleUser) {
    return await GetEndUserRedirect(teamMemberInfo, store);
  }
  return await GetTeamMemberRedirect(teamMemberInfo, store);
}

async function GetAssessmentRedirect(redirect, store) {
  let type = redirect.assessmentType;
  let id = redirect.id;
  let results = await getAssessmentResult(id, type);
  let name = assessmentRoutes[type];
  let route = {
    name: name,
    params: {
      assessmentId: id,
      loadedResults: results
    }
  };
  store.commit("setRedirectAssessment", null);
  return route;
}

function GetWellnessRedirect(store) {
  if (store.getters.redirectToWellness) {
    store.commit("setWellnessRedirect", false);
  }

  return routes.WELLNESS;
}

async function GetEndUserRedirect(teamMemberInfo, store) {
  if (teamMemberInfo.endUserSignedUpToTeam) {
    return { name: GetWellnessRedirect(store) };
  }
  let availableTeams = await getActiveTeamForEndUserEmail();
  if (availableTeams?.teams.length > 0) {
    return {
      name: routes.JOIN_TEAM,
      params: { endUserTeams: availableTeams.teams }
    };
  } else {
    return { name: routes.CREATE_TEAM };
  }
}

async function GetTeamMemberRedirect(teamMemberInfo, store) {
  if (teamMemberInfo.teamsToJoinRequested.length > 0) {
    return {
      name: routes.CHOOSE_TEAM,
      params: { teams: teamMemberInfo.teamsToJoinRequested }
    };
  }

  let wellnessRedirect = store.getters.redirectToWellness;
  let assessmentRedirect = store.getters.redirectReportAssessment;
  var name =
    assessmentRedirect || wellnessRedirect
      ? GetWellnessRedirect(store)
      : routes.DASHBOARD;
  return { name };
}

function recordLogins(destination, store) {
  let notJoinOrCreateTeam =
    destination?.name !== routes.JOIN_TEAM &&
    destination?.name !== routes.CREATE_TEAM;
  if (notJoinOrCreateTeam) {
    if (store.getters.isSimpleUser) {
      recordEndUserLogin();
    } else {
      recordTeamMemberLogin();
    }
  }
}

async function setTeamMemberData(teamMemberInfo, store) {
  if (store.getters.isSimpleUser) {
    return;
  }
  store.commit("setTeamMemberId", teamMemberInfo.teamMemberId);
  if (teamMemberInfo.teamsToJoinRequested.length === 0) {
    await checkHasSubscriptionAndSetCustomerId();
  }
}

export async function completeUserSignInWithoutRedirect(store) {
  recordLogins(null, store);
}

export async function completeUserSignIn(store, router) {
  const urlParams = new URLSearchParams(window.location.search);
  const msTeamsAuth = urlParams.get("msTeamsAuth");

  if (msTeamsAuth) {
    router.push({ name: routes.MS_TEAMS_DASHBOARD });
    return;
  }

  let teamMemberInfo = await TeamService.getTeamMemberInfo();
  if (teamMemberInfo?.endUserSignedUpToTeam) {
    await TeamService.updateEndUserInfo(); // only call if exists as part of team
  }
  await setTeamMemberData(teamMemberInfo, store);

  // if admins has an assessment to view from an external link take them to user profile and open report
  const adminAssessmentToViewUrl = urlParams.get("admin-assessment-to-view");
  const adminAssessmentToView =
    store.getters.adminAssessmentToView ?? adminAssessmentToViewUrl;
  if (adminAssessmentToView) {
    const success = await redirectToAdminAssessment(adminAssessmentToView);
    if (success) return; // only continue if successful, otherwise direct to regular login flow.
  }

  let destination = null;
  // if redirect page exist then get destination from there, if it fails or doesn't exist
  // go through normal getRedirectDestination method
  if (store.getters.redirectPage) {
    destination = await handleUrlRedirect();
  }
  if (!destination) {
    destination = await getRedirectDestination(teamMemberInfo, store);
  }
  recordLogins(destination, store);
  if (!destination) {
    throw Error("No router destination found");
  }
  router.push(destination).catch(() => {});
}

async function redirectToAdminAssessment(assessmentId) {
  try {
    const assessmentDetails = await getAssessmentDetails(assessmentId);

    await router.push({
      path: `/people/${assessmentDetails.userId}`,
      props: { userId: assessmentDetails.userId }
    });

    await store.dispatch("userProfile/viewAssessment", {
      id: assessmentDetails.id,
      assessmentType: assessmentDetails.assessmentType,
      lastUpdatedAt:
        assessmentDetails.lastUpdatedAt ?? assessmentDetails.completedAt
    });
    return true;
  } catch (err) {
    sentry.captureException(
      new Error(`Failed to redirect admin to report: ${err.message}`),
      {
        extra: {
          assessmentId: assessmentId
        }
      }
    );
    return false; // silently fail to the user so they get directed to dashboard without any hitches
  } finally {
    store.commit("setAdminAssessmentToView", null);
  }
}
