import { API, Auth } from "aws-amplify";
import SentryClient from "@/sentry";
import { AbortError } from "./restErrors";
import * as microsoftTeams from "@microsoft/teams-js";

const apiName = "ApiGateway";
const websocketName = "ApiGatewayWebSocket";
const sessionHeaderKey = "vitrue-vida-session-id";

async function UpdateAuthorizationHeaders(headers) {
  if (!headers) headers = {};
  
  var vidaSessionId = localStorage.getItem(sessionHeaderKey);
  if (!!vidaSessionId) headers[sessionHeaderKey] = vidaSessionId;
  if(sessionStorage.getItem("teamsAuthToken")) {
    try {
      // await microsoftTeams.app.initialize();   
      const authToken = sessionStorage.getItem("teamsAuthToken");//await microsoftTeams.authentication.getAuthToken();
      headers.Authorization = `Bearer ${authToken}`;
      return headers;
    } catch (e) {
    }
  }
        
  try {
    const session = await Auth.currentSession();
    if (session && session.isValid) {
      headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
    }
    return headers;
  } catch (e) {
    return {};
  }
}

async function getApiInit(defaultInit) {
  let myInit = defaultInit || {};
  myInit.headers = await UpdateAuthorizationHeaders();
  myInit.response = true;
  return myInit;
}

async function makeRequest(request) {
  try {
    let response = await request();
    if (response.headers[sessionHeaderKey] !== undefined) {
      localStorage.setItem(
        sessionHeaderKey,
        response.headers[sessionHeaderKey]
      );
    }
    return response.data;
  } catch (errorResponse) {
    SentryClient.captureRestException(errorResponse);
    if (
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.error
    ) {
      throw errorResponse.response.data.error;
    }

    throw errorResponse;
  }
}

export default {
  async post(path, body) {
    let apiInit = await getApiInit();
    if (body) {
      apiInit.body = body;
    }
    return await makeRequest(() => API.post(apiName, path, apiInit));
  },
  async get(path) {
    let apiInit = await getApiInit();
    return await makeRequest(() => API.get(apiName, path, apiInit));
  },
  async delete(path, body) {
    let apiInit = await getApiInit();
    if (body) {
      apiInit.body = body;
    }
    return await makeRequest(() => API.del(apiName, path, apiInit));
  },
  async put(path, body) {
    let apiInit = await getApiInit();
    if (body) {
      apiInit.body = body;
    }
    return await makeRequest(() => API.put(apiName, path, apiInit));
  },
  async getWithSignal(path, signal) {
    const endpoint = (await API.endpoint(apiName)) + path;
    const headers = await UpdateAuthorizationHeaders();

    const options = {
      method: "GET",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*"
      },
      signal
    };

    try {
      const response = await fetch(endpoint, options);

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error?.message ||
          "There was a problem with your request. Please try again.";
        throw new Error(errorMessage);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      if (error.name === "AbortError") {
        throw new AbortError();
      }
      SentryClient.captureRestException(error);
      throw error;
    }
  },
  async postWithSignal(path, body, signal) {
    const apiEndpoint = (await API.endpoint(apiName)) + path;
    const headers = await UpdateAuthorizationHeaders();

    const options = {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*"
      },
      body: JSON.stringify(body),
      signal
    };

    try {
      const response = await fetch(apiEndpoint, options);

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error?.message ||
          "There was a problem with your request. Please try again.";
        throw new Error(errorMessage);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      if (error.name === "AbortError") {
        throw new AbortError();
      }
      SentryClient.captureRestException(error);
      throw error;
    }
  },
  async *postStream(path, body, signal) {
    const apiEndpoint = (await API.endpoint(apiName)) + path;
    const headers = await UpdateAuthorizationHeaders();

    const options = {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*"
      },
      body: JSON.stringify(body),
      signal
    };

    try {
      const response = await fetch(apiEndpoint, options);
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error?.message ||
          "There was a problem with your request. Please try again.";
        throw new Error(errorMessage);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        yield decoder.decode(value, { stream: true });
      }
    } catch (error) {
      if (error.name === "AbortError") {
        throw new AbortError();
      }
      SentryClient.captureRestException(error);
      throw error;
    }
  },
  sessionHeaderKey
};
